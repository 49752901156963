import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { changePassword, login } from '../services/User/authService';
import { FormGroup, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        TabanTebParseh
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
export default function LogIn() {
const navigate = useNavigate();
  const[msg,setMsg]=React.useState();
  const[changePasswordMode,setChangePasswordMode]=React.useState(false);
  const handleSubmit =async (event) => {
    event.preventDefault();
    if(changePasswordMode) {await initChangePassword(event);return;};
    try{
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    setMsg();
    const res=await login(data.get('email'),data.get('password'));
    if(res && res.token)
    {
      console.log("MyResp:",res)
      localStorage.setItem("user",JSON.stringify(res));
      localStorage.setItem("token",res.token);
      navigate('/home');
    }
    else
      setMsg("نام کاربری یا رمز عبور اشتباه است")
  }
  catch{
    setMsg("نام کاربری یا رمز عبور اشتباه است")
  }
  };
const initChangePassword=async(event)=>{
  const data = new FormData(event.currentTarget);
    if(changePasswordMode)
    {
      const res=await changePassword(data.get('email'),data.get('password'),data.get('newPassword'));
      if(res && res.token)
      {
        setChangePasswordMode(false);
        setMsg("رمز عبور با موفقیت تغییر یافت")
      }
      else
        setMsg("نام کاربری یا رمز عبور قبلی اشتباه است")
      }
    else
      setChangePasswordMode(true);
}
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
             ورود به سیستم
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="نام کاربری"
              name="email"
              //autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="رمز عبور"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {changePasswordMode && <TextField
              margin="normal"
              fullWidth
              name="newPassword"
              label="رمز عبور جدید"
              type="password"
              id="newPassword"
              autoComplete="current-password"
            />}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {!changePasswordMode ? 'ورود' :'تغییر رمز' }
            </Button>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={changePasswordMode} onChange={()=>setChangePasswordMode(!changePasswordMode)} />} label='تغییر رمز' />
            </FormGroup>

            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  رمز عبور خود را فراموش کرده ام؟
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"آیا حساب کاربری ندارید؟ ثبت نام"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          {msg && <Snackbar
        open={msg}
        autoHideDuration={3000}
        //onClose={handleClose}
        message={msg}
      />}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}