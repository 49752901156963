import React, { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { getUserList } from "../services/User";
import { deepOrange } from "@mui/material/colors";
import { FormatNumberBy3, stringAvatar } from "../utils";
import { Button, Paper, Snackbar } from "@mui/material";
import UserCard from "../component/card/UserCard";
import AddUserPage from "./modal/AddUserPage";
import { AddCircle, PlusOneRounded, PlusOneTwoTone } from "@mui/icons-material";
import DeviceCard from "../component/card/DeviceCard";


export default function DeviceList({data,onSuccessAdd}) {
    const[showAddUser,setShowAddUser]=useState();
    const[message,setMessage]=useState('');
    const handleOnSuccessAdd=()=>{
      setMessage('با موفقیت ثبت شد');
      setShowAddUser(undefined);
      if(onSuccessAdd) onSuccessAdd();
      setTimeout(() => {
        setMessage();
      }, 5000);
    }
  return (
    <Paper style={{ maxHeight: window.innerHeight-100, overflow: 'auto' }}>
    <List sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
      {data?.map((item,index)=>{
        return (
            <div key={'user'+index}>
            <DeviceCard item={item}/>
            <Divider variant="inset" component="li"  style={{minWidth:'100%'}} />
      </div>
        )
      })}
    </List>
    <Button style={{position:'absolute',left:20,bottom:20}} ><AddCircle fontSize="large" color="secondary" onClick={()=>setShowAddUser(true)}/></Button>
    {showAddUser!=undefined && <AddUserPage onClose={()=>setShowAddUser(undefined)} onSuccessAdd={handleOnSuccessAdd} />}
    <Snackbar
      open={message}
      //onClose={handleClose}
      message={message}
      //action={action}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      color='red'
      
    />
    </Paper>
  );
}
