import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// const data = [
//     { name: '1', uv: 4000, pv: 45, amt: 2400 },
//     { name: '2', uv: 3000, pv: 23, amt: 2210 },
//     { name: 'Mar', uv: 2000, pv: 45, amt: 2290 },
//     { name: 'Apr', uv: 2780, pv: 12, amt: 2000 },
//     { name: 'May', uv: 1890, pv: 43, amt: 2181 },
//     { name: 'Jun', uv: 2390, pv: 56, amt: 2500 },
//     { name: 'Jul', uv: 3490, pv: 37, amt: 2100 },
//   ];
  
const LinearChart = ({data}) => {
    return (
      <div style={{textAlign:'center'}}>
      <LineChart style={{width:'100%'}} reverseStackOrder width={500} height={300} data={data}>
        <XAxis dataKey="name" />
        <YAxis tickMargin={20}/>
        {/* <CartesianGrid stroke="#eee" strokeDasharray="5 5" /> */}
        <Tooltip />
        <Legend  />
        {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
        <Line type="monotone" name='آخرین دمای دریافتی از هر دیوایس' dataKey="temp3"  stroke="#a2ae9d" dot={false} strokeWidth={2} />
        <Line type="monotone" name='دمای دیوایس ۱' dataKey="temp" stroke="#8884d8" dot={false} activeDot={{ r: 8 }} strokeWidth={2} />
        <Line type="monotone" name='دمای دیوایس ۲' dataKey="temp2" stroke="#82ca9d" dot={false} strokeWidth={2} />
        
      </LineChart>
      </div>
    );
  }
  
  export default LinearChart;
  