import React from 'react'
import { downloadFile, postRequest, requestServer, sendRequest } from '../../utils'

export const GetDeviceList=async()=>{
    return await requestServer("device/GetDeviceList");
}
export const GetLastStatus=async()=>{
    return await requestServer("device/GetLastStatus");
}

export const GetLastStatusByDeviceName=async(name)=>{
    return await requestServer("device/GetLastStatusByDeviceName/"+name);
}
