import React, { useEffect, useState } from 'react'
import FundCard from '../component/card/FundCard'
import { Button } from '@mui/material'
import { downloadFile } from '../utils'
import { GetBackupTransactions, GetBackupUsers } from '../services/User'
import { Backup, DeviceHub, DeviceThermostat, ExitToApp, MonitorHeart, TempleHindu } from '@mui/icons-material'
import { logout } from '../services/User/authService'
import { useNavigate } from 'react-router-dom'
import { GetLastStatus, GetLastStatusByDeviceName } from '../services/Device'

export default function LastStatus({onGetData}) {
  const navigate = useNavigate();
  const[last,setLast]=useState();
  const[lastStatus,setLastStatus]=useState({});
  const[reload,setReload]=useState(true);
  const getBackup=async()=>{
    const res=await GetBackupUsers();
    const res2=await GetBackupTransactions();
  }
  const exit=()=>{
    logout();
    navigate('/login')
  }
  useEffect(() => {
    if(last)
    {
      setReload(false)
      setTimeout(() => {
        setReload(true);
      }, 3000);
    }
  }, [last])
  useEffect(() => {
    if(lastStatus)
      onGetData(lastStatus);
  }, [lastStatus])
  useEffect(() => {
    if(reload)
      getLastStatus();
  }, [reload])
  const getLastStatus=async()=>{
    console.log('Start Get Data');

    const res1=await GetLastStatusByDeviceName('device1');
    //if(res1.isSuccessStatus) setLastStatus({id:1,value:res1.data});

    const res2=await GetLastStatusByDeviceName('device2');
    

    const res=await GetLastStatus();
    if(res.isSuccessStatus){
      setLast(res.data);
      setLastStatus([{id:3,value:(res.data.name!=='device1' && res.data.name!=='device2') ? res.data : {}},{id:1,value:res1.data},{id:2,value:res2.data}]);
    }
  }
  return (
    <div style={{textAlign:'center'}}>
      {/* <div>Last: {JSON.stringify(last)}</div> */}
      <Button style={{marginTop:10}} startIcon={<DeviceThermostat style={{marginLeft:10,marginRight:0}} />} variant='contained' color='warning' onClick={exit}>{last?.temp}</Button>
        <Button style={{marginTop:10}} startIcon={<MonitorHeart style={{marginLeft:10,marginRight:0}} />} variant='contained' color='secondary' onClick={getBackup}>{last?.name}</Button>
    </div>
  )
}
