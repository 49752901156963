import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UserList from './UserList';
import { Button, CircularProgress, TextField } from '@mui/material';
import { addUser, getDashboard, getNotPaidUsers, getTransactionList, getUserList } from '../services/User';
import TransactionList from './TransactionList';
import Dashboard from './Dashboard';
import { GetDeviceList } from '../services/Device';
import DeviceList from './DeviceList';
import LastStatus from './LastStatus';
import LinearChart from '../component/chart/LinearChart';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function HomePage() {
  const [value, setValue] = React.useState(4);
  const[data,setData]=React.useState([]);
  const[filterData,setFilterData]=React.useState([]);
  const[dataNotPaid,setDataNotPaid]=React.useState([]);
  const[transactions,setTransactions]=React.useState([]);
  const[dashboardData,setDashboardData]=React.useState({});
  const[loading,setLoading]=React.useState(false);
  const[lastData,setLastData]=React.useState([]);
  const initTransactions = async () => {
    setLoading(true);
    const list= await getTransactionList();
    setFilterData(list);
    setTransactions(list);
    setLoading(false);
  };
  const setUserList = async () => {
        setLoading(true);
        setData([]);
        const list= await getUserList();
        setFilterData(list);
        setData(list);
        setLoading(false);
    };
    const setUserListNotPaid = async () => {
      setLoading(true);
        const list= await getNotPaidUsers();
        setFilterData(list);
        setDataNotPaid(list);
        setLoading(false);
    };
    const initDashboard = async () => {
      setLoading(true);
        const res= await getDashboard();
        setLoading(false);
        console.log('RSSS: ',res)
        if(res==null) return;
        setDashboardData( {info:{remain:res.remain,totalBedehkari:res.totalBedehkari,totalBestankari:res.totalBestankari}})
    };
    const getDeviceList = async () => {
      setLoading(true);
        const res= await GetDeviceList();
        setLoading(false);
        console.log('RSSS: ',res)
        if(res==null) return;
        setFilterData(res.data);
        setData(res.data);
        
    };
  const handleChange = async(event, newValue) => {
    if(newValue==0)
        await setUserList();
    else if(newValue==1)
        await initTransactions();
    else if(newValue==2)
        await initDashboard();
    else if(newValue==3)
        await setUserListNotPaid();
    else if(newValue==4)
        await getDeviceList();
    setValue(newValue);
  };
  React.useEffect(() => {
    if(value==4)
        handleChange(null,value);
  }, [value])
  
  const handleSearch=(event)=>{
    let txt=event.target.value;
    let filter=[];
    if(value==0)
       filter=data.filter(p=>p.name.includes(txt) || p.mobile.toString().includes(txt) || p.id.toString().includes(txt) || p.family.includes(txt));// || p.family?.includes(family) || p.id.includes(txt) || p.mobile.includes(txt));
    else if(value==1)
      filter=transactions.filter(p=>p.name.includes(txt) || p.mobile.toString().includes(txt) || p.userId.toString().includes(txt) || p.family.includes(txt));// || p.family?.includes(family) || p.id.includes(txt) || p.mobile.includes(txt));
    else if(value==3)
      filter=dataNotPaid.filter(p=>p.name.includes(txt) || p.mobile.toString().includes(txt) || p.id.toString().includes(txt) || p.family.includes(txt));// || p.family?.includes(family) || p.id.includes(txt) || p.mobile.includes(txt));
    else if(value==4)
      filter=data.filter(p=>p.name.includes(txt));
    setFilterData(filter);
  }
  const handleOnSuccessAddUser=()=>{
    setUserList();
  }
  const removeLastItem = () => {
    // console.log("MMARR: ",lastData.splice(0, 1))
    setLastData(lastData.splice(0, 1));
  };
  const pushArray=(data)=>{
    let tmp=[...lastData,{name:'',temp: data.temp,temp2: data.temp-2}];
    if(tmp.length>3) tmp= tmp.splice(tmp.length-1, 1);
    setLastData(tmp)
  }
  const addItem = (data) => {
    if(data[0]?.id==undefined) return;
    console.log('DD:',data)
    //return;
    //return;
    let tmp=lastData;
    tmp=([...lastData, { name:'',temp3:data[0]?.value.temp, temp:data[1]?.value.temp,temp2:data[2]?.value.temp}]);
    if (tmp.length > 10)
      tmp=(tmp.slice(1));
    setLastData(tmp);
  };
  return (
    <Box sx={{ width: '100%',direction:'rtl' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="لیست اعضا" {...a11yProps(0)} />
          <Tab label="آخرین تراکنش ها" {...a11yProps(1)} />
          <Tab label="داشبورد" {...a11yProps(2)} />
          <Tab label="بدهکاران" {...a11yProps(3)} />
          <Tab label="آمار دیوایس ها" {...a11yProps(4)} />
        </Tabs>
        {loading && <CircularProgress />}
      </Box>
      {value!=4 && <TextField style={{paddingRight:30,paddingTop:30,width:320}} onChange={handleSearch} placeholder='جستجو' variant="standard" color="warning" focused />}
      <CustomTabPanel value={value} index={0}>
          <UserList data={filterData} onSuccessAdd={handleOnSuccessAddUser}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TransactionList data={filterData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Dashboard data={dashboardData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <UserList data={filterData}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <LinearChart data={lastData} />
        {/* <div>{lastData?.length}</div>
        <div>{JSON.stringify(lastData)}</div> */}
        <LastStatus onGetData={addItem} />
        <TextField style={{paddingRight:30,paddingTop:30,width:320}} onChange={handleSearch} placeholder='جستجو' variant="standard" color="warning" focused />
        <DeviceList data={filterData}/>
      </CustomTabPanel>
      {/* // const data = [
//     { name: '1', uv: 4000, pv: 45, amt: 2400 },
//     { name: '2', uv: 3000, pv: 23, amt: 2210 },
//     { name: 'Mar', uv: 2000, pv: 45, amt: 2290 },
//     { name: 'Apr', uv: 2780, pv: 12, amt: 2000 },
//     { name: 'May', uv: 1890, pv: 43, amt: 2181 },
//     { name: 'Jun', uv: 2390, pv: 56, amt: 2500 },
//     { name: 'Jul', uv: 3490, pv: 37, amt: 2100 },
//   ]; */}
    </Box>
  );
}
